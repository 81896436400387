import React from 'react'
import { Container } from 'react-bootstrap'

const TermsConditions = () => {
    return (
        <div>
            <Container>
                <div style = {{
                    boxShadow : '0px 0px 15px -10px',
                    padding : 40,
                    margin : 20,
                    textAlign: 'center'
                }}>
                    <a href = "/" style = {{fontWeight : 700, textAlign: 'center'}}>
                    JUNO TERMS AND CONDITIONS
                        </a>
                        <hr/>

                <p >
                Welcome to Juno, a lifestyle and rental platform aimed at providing affordable accommodations with the right amenities for young people. Before you begin, please take some time to read the important Terms of Use below. We look forward to having you on Juno. 
                </p>

                <p style = {{fontWeight : 500, textDecoration : 'underline'}}>Terms of Use Agreement</p>
 <div style = {{
                  
                    textAlign: 'left'
                }}>
                <p>This Terms of Use Agreement, along with the License Agreement, Members Operations Manual, any exhibits, disclosures, addendums or amendments hereto, as the same may be amended or modified from time to time (collectively, the "Agreement") constitutes an agreement between you ("you" , "your" , "User" or "Client") and Saunders Development Company Limited, and/or its affiliates which sets forth the terms and conditions applicable to your access and use of Juno via our website located at www.juno.africa (collectively, the "Platform") and the services that we offer to you through the Platform (each, a "Service"). When you access the Platform, you are agreeing to be bound by the following Terms and Conditions, as well as the Privacy Policy.</p>
                <p>For the purposes of these Terms and Conditions, the term “SDCL” refers to Saunders Development Company Limited, its affiliates, subsidiaries, partners, agents and employees.</p>
                <p>Your use of the Platform and/or Services is subject to our License Agreement which addresses the way and manner in which we provide rental and other ancillary services and our Privacy Policy which addresses how we gather, use, disclose and manage your personal information. Please read this Agreement and our Privacy Policy carefully.</p>

                <p>By accessing or using the Platform or any Service, you understand and agree that:
                <ol type = 'a'>
                    <li>You represent and warrant to us that you are able to enter into legally binding contracts, and you agree that this Agreement legally binds you in the same manner that a signed, written, paper contract does. You may not use the Platform and/or Services in any manner or attempt to access the Platform and/or any Service if you are not willing to be bound and abide by this Agreement.</li>
                    <li>We reserve the right to change or modify this Agreement or our Privacy Policy, or modify or discontinue any portion of the Services or features and functionality provided through the Platform, from time to time without prior notice to you (except where required by law ). If we decide to change this Agreement or our Privacy Policy, we will post such changes on the Platform and such changes will be effective from such change. We reserve the right to modify or temporarily discontinue your access to the Platform or portion thereof, or any Service, with or without prior notice to you. You agree that we shall not be liable to you or any third party for any modification of the Platform, the Services, this Agreement, or your access to the Platform or Services.</li>
                    <li>Subject to the terms of this Agreement, SDCL grants you a revocable, non-transferable, nonexclusive limited license to access and use those portions of the Platform and those Services to which you are granted access.</li>
                </ol>

                <ol >
                    <li style = {{fontWeight : 700}}>Eligibility

                        <p  style = {{fontWeight : 400}}>In order to use or access the Platform or subscribe/register for our Services, you represent and warrant that you are at least 18 years of age and are legally able to enter into a binding contract. You authorize us, directly or through third parties, to make any inquiries we consider necessary to validate your identity.</p>
                    </li>

                    <li style = {{fontWeight : 700}}>Registration

                        <p  style = {{fontWeight : 400}}>
                        To obtain full access to the Platform, you will be required to complete a registration process. You will be asked to provide certain registration details or other information; to establish your account on the Platform (‘Account’). By signing up on the Platform, you agree and understand that you are responsible for maintaining the confidentiality of your password which together with your Login ID (your email address), allows you to access the Platform. That Login ID and password, together with any other registration information constitute your "Registration Information". You represent and warrant that all required Registration Information you submit is true, accurate, current and complete, and where applicable, that you will update all such Registration Information as necessary to maintain its truth, accuracy and completeness. What constitutes Registration Information is located in the Privacy Policy. 
                        <br/>
                        <br/>
                        You agree not to create an account on the Platform if you have been previously removed by us or banned from using the Platform. You agree not to create an account using a false identity or information, or on behalf of someone other than yourself save with express permission and following the strict instructions of such person(s) on whose behalf an account is to be created, provided that, such account upon creation shall immediately be transferred to or managed on behalf of such person(s). We reserve the right in our sole discretion to suspend or terminate your account and/or refuse any and all current or future use of the Platform.
                        <br/>
                        <br/>
                        You are responsible for all activities that occur under your Account. You agree to immediately notify us at support@juno.africa of any unauthorized use, suspected unauthorized use of your Account or any other breach of security. We will not be liable for any loss or damage arising from your failure to comply with the above requirements. Your use of the Platform is personal to you and not transferable to any other person or entity.
                        <br/>
                        <br/>
                        SDCL relies on information provided by you in order to generate your recommendations and cannot be held responsible for any recommendations based on inaccurate information. You acknowledge that if you provide false or inaccurate information on the Platform, the rental options provided to you may not match your accommodation needs. 
                        </p>
                    </li>


                    <li style = {{fontWeight : 700}}>Booking Policy

<p  style = {{fontWeight : 400}}>Virtual tours are available to all members via <a href = "https://zfrmz.com/JLCm2X7awhZnl45PTSfW">link</a> to view the property prior to booking your space. The Platform provides different types of accommodation including: shared bunk spaces, one bedroom apartments, studio apartments and loft apartment spaces.  This is followed by your selection of an available plan indicating the duration of your stay and payment plan. You are also required to provide a guarantor whose details will be requested in the process of making your booking. Upon completion, you will be redirected to a payment page. Your booking is also confirmed upon payment of your rent.</p>
</li>

<li style = {{fontWeight : 700}}>Termination and Refund Policy

                        <p  style = {{fontWeight : 400}}>In the event you have not taken possession of your apartment following your successful booking and wish to cancel your booking, you may do so within 5 (five) days of your booking date. Upon completion of your check out process, your Security Deposit Fee will be refunded to you within 14 days.
                        <br/>
<br/>
For any damages (outside of general wear and tear) allocated costs will be deducted from your deposit. 
<br/>
<br/>
Early Termination refunds will only be applicable for memberships above 1year.
<br/>
<br/>
Termination may be initiated by sending an email to support@juno.africa indicating the apartment type, Juno plan and account username.
</p>
                    </li>

                    <li style = {{fontWeight : 700}}>Payment and Fees

<p  style = {{fontWeight : 400}}>You shall pay the full rent indicated against each apartment under the selected plan. Payment shall be made via bank transfer, credit or debit cards. Once registered,you agree to pay all costs and fees associated with the additional services provided by the Platform which you subscribe for. Such additional services include personal laundry, cleaning and catering services.</p>
</li>

<li style = {{fontWeight : 700}}>Copyright and Other Intellectual Property 

                        <p  style = {{fontWeight : 400}}>SDCL maintains the Platform and is the owner or the authorized user of all text, images, graphics, photos, animation, music, sounds and other materials contained within the Platform. The materials contained within the Platform, including, without limitation, any copyrights, trademarks, service marks, and all other proprietary materials, are protected by the Nigerian and Urbanistnational copyright laws and treaty provisions, trademarks laws, and other proprietary rights laws. SDCL also owns a copyright in the selection, coordination and arrangement of the material contained within the Platform. The material contained within the Platform is provided by SDCL only for lawful uses by customers, SDCL employees, and members of the general public. The material may not be copied, republished, incorporated into another website or reproduced (whether by linking, framing, or any other method), transmitted, distributed, uploaded, posted, used to create a derivative work or exploited in any other way without the express written consent of SDCL.</p>
                    </li>

                    <li style = {{fontWeight : 700}}>Browsers

<p  style = {{fontWeight : 400}}>The Platform supports the two latest versions of the following web browsers: Google Chrome, Microsoft Edge, Firefox, and Safari. Due to the rapidly evolving nature of web technology, we cannot guarantee the functionality of any information offered on the Platform unless viewed in a supported browser.</p>
</li>

<li style = {{fontWeight : 700}}>Third Party Links

                        <p  style = {{fontWeight : 400}}>Clicking on certain links within the Platform or certain other websites that are linked to the Website may take you to other websites, or may display information on your computer screen from other websites, which may not be maintained by SDCL. Such websites may contain terms and conditions, privacy provisions, confidentiality provisions, or other provisions that may differ from the terms and conditions applicable to the Platform. Links to other Urbanistnet services and websites are provided solely for the convenience of users. A link to any service or website is not an endorsement of any kind of the service or website, its content, or its sponsoring organization.<br/><br/>
SDCL assumes no responsibility or liability whatsoever for the content, accuracy, reliability or opinions expressed in a website, to which the website is linked (a "linked website" ) and such linked websites are not monitored, investigated , or checked for accuracy or completeness by SDCL. It is your responsibility to evaluate the accuracy, reliability, timeliness and completeness of any information available on a linked website. All products, services and content obtained from a linked website are provided "AS IS" without warranty of any kind, express or implied, including, but not limited to, implied warranties of merchantability, fitness for a particular purpose, title, non-infringement, security, or accuracy.
</p>
                    </li>

                    <li style = {{fontWeight : 700}}>Limited Liability

<p  style = {{fontWeight : 400}}>Neither SDCL nor any other party involved in the creation, production or delivery of the information on the Platform, nor the officers, directors, employees or representatives of SDCL, are liable in any way for any indirect, special, punitive, consequential, or indirect damages (including without limitation lost profits, cost of procuring substitute service or lost opportunity) arising out of or in connection with the Platform or the use of the Platform or a linked website or with the delay or inability to use the website or a linked website, whether or not SDCL is made aware of the possibility of such damages. This limitation includes, but is not limited to, the transmission of any viruses, data or harmful code that may affect your equipment or anyone else's equipment, any incompatibility between the Platform's files and your browser, mobile device or other website accessing program, or any failure of any electronic or telephone equipment, communication or connection lines, unauthorized access, theft, operator errors, or any force majeure.<br/><br/>
SDCL does not guarantee continuous, unUrbanistrupted or secure access to the Platform or a linked website. The content, accuracy, opinions expressed, and other links provided by linked websites are not investigated, verified, monitored or endorsed by SDCL. The information, software, products and description of services published on the Platform or a linked website may include inaccuracies or typographical errors, and SDCL specifically disclaims any liability for such inaccuracies or errors. Changes are periodically made to the information on the Platform and linked websites. SDCL may make improvements or changes to the Platform at any time.
</p>
</li>

<li style = {{fontWeight : 700}}>No Warranties

                        <p  style = {{fontWeight : 400}}>Neither SDCL nor any other party involved in the creation, production or delivery of the information on the Platform, nor the officers, directors, employees or representatives of SDCL, are liable in any way for any indirect, special, punitive, consequential, or indirect damages (including without limitation lost profits, cost of procuring substitute service or lost opportunity) arising out of or in connection with the Platform or the use of the Platform or a linked website or with the delay or inability to use the website or a linked website, whether or not SDCL is made aware of the possibility of such damages. This limitation includes, but is not limited to, the transmission of any viruses, data or harmful code that may affect your equipment or anyone else's equipment, any incompatibility between the Platform's files and your browser, mobile device or other website accessing program, or any failure of any electronic or telephone equipment, communication or connection lines, unauthorized access, theft, operator errors, or any force majeure.<br/><br/>
SDCL does not guarantee continuous, unUrbanistrupted or secure access to the Platform or a linked website. The content, accuracy, opinions expressed, and other links provided by linked websites are not investigated, verified, monitored or endorsed by SDCL. The information, software, products and description of services published on the Platform or a linked website may include inaccuracies or typographical errors, and SDCL specifically disclaims any liability for such inaccuracies or errors. Changes are periodically made to the information on the Platform and linked websites. SDCL may make improvements or changes to the Platform at any time.
</p>
                    </li>

                    <li style = {{fontWeight : 700}}>Confidentiality of Information

<p  style = {{fontWeight : 400}}>SDCL has taken reasonable steps to ensure the confidentiality of information provided on the Platform by the user and transmitted via the Urbanistnet. However, unexpected changes in technology may be used by unauthorized third parties to Urbanistcept confidential information and we cannot be responsible should confidential information be Urbanistcepted and subsequently used by an unintended recipient.</p>
</li>

<li style = {{fontWeight : 700}}>Choice of Law and Dispute Resolution

                        <p  style = {{fontWeight : 400}}>All activities on the Platform and these Terms and Conditions are governed by the laws of the Federal Republic of Nigeria excluding provisions on conflict and choice of laws. You agree that all disputes in arising from the use of the Platform will be resolved by way of binding arbitration. The Lagos State Multi-Door Courthouse will have exclusive jurisdiction to hear and determine any claims or disputes between you and SDCL, pertaining directly or indirectly to these Terms and Conditions, or to any matter arising from these Terms and Conditions, or any other document executed and delivered in connection with these Terms and Conditions, the use of the Platform or the Services offered by SDCL. You and SDCL each agree to submit to arbitration under Nigerian Law.</p>
                    </li>

                    <li style = {{fontWeight : 700}}>Website Content and Material

<p  style = {{fontWeight : 400}}>The information and materials contained on the Platform, including but not limited to these Terms and Conditions and any product information, are subject to change without notice. You are deemed to be apprised of and bound by any such changes. Not all products and services are available in all geographic areas. Your eligibility for particular products and services is subject to final determination and acceptance by us.</p>
</li>

<li style = {{fontWeight : 700}}>Waiver and Severability

                        <p  style = {{fontWeight : 400}}>Any waiver of any provision contained in these Terms and Conditions shall not be deemed to be a waiver of any other right, term or provision of these Terms and Conditions. If any provision in these Terms and Conditions shall be or become wholly or partially invalid, illegal or unenforceable, such provision shall be enforced to the extent it is legal and valid and the validity, legality and enforceability of the remaining provisions shall in no way be affected or impaired thereby.</p>
                    </li>

                    <li style = {{fontWeight : 700}}>Access and Urbanistference

<p  style = {{fontWeight : 400}}>You agree not to engage in any of the following: 
<ul>
    <li>Use any robot, spider, scraper, deep link or other similar automated data gathering or extraction tools, program, algorithm or methodology to access, acquire, copy or monitor the Platform or any portion of the Platform, without SDCL's express written consent, which may be withheld in SDCL’s sole discretion.</li>
    <li>Use or attempt to use any engine, software, tool, agent, or other device or mechanism (including without limitation browsers, spiders, robots, avatars or intelligent agents) to navigate or search the Platform.</li>
    <li>Post or transmit any file which contains viruses, worms, Trojan horses or any other contaminating or destructive features, or that otherwise Urbanistfere with the proper working of the Platform.</li>
    <li>Attempt to decipher, decompile, disassemble, or reverse-engineer any of the software comprising or in any way making up a part of the Platform.</li>
    </ul></p>
</li>

<li style = {{fontWeight : 700}}>Secured Areas

                        <p  style = {{fontWeight : 400}}>Access to and use of password protected and/or secure areas of the Platform is restricted to authorized users only. Unauthorized persons attempting to access these areas of the Platform may be subject to prosecution</p>
                    </li>

                    <li style = {{fontWeight : 700}}>Telephone Call Monitoring and Recording

<p  style = {{fontWeight : 400}}>In order to use or access the Platform or subscribe/register for our Services, you represent and warrant that you are at least 18 years of age and are legally able to enter into a binding contract. You authorize us, directly or through third parties, to make any inquiries we consider necessary to validate your identity.</p>
</li>

<li style = {{fontWeight : 700}}>Telephone Call Monitoring and Recording

                        <p  style = {{fontWeight : 400}}>Authorized employees or agents of SDCL may monitor and record all or portions of your telephone calls to SDCL for quality control, customer service, employee training, security, legal compliance, and other lawful purposes. Your consent will be ongoing and need not be confirmed prior to, or during such monitoring or recording, except to the extent applicable law expressly requires otherwise.</p>
                    </li>

                    <li style = {{fontWeight : 700}}>Electronic Communications

<p  style = {{fontWeight : 400}}>The Platform requests for your e-mail addresses so that you may communicate electronically by sending an e-mail message to SDCL. All e-mail sent to and from SDCL will be received or otherwise recorded by the SDCL corporate e-mail system and is subject to archival, monitoring or review by and/or disclosure to, someone other than the recipient.<br/><br/>
Communications through the Platform may involve the electronic transmission, to any e-mail address you provided to us, of information that you may consider to be personal financial information and you agree and consent to such transmission of such information. You agree not to use e-mail to transmit any confidential personal information. It is your responsibility to update or change your e-mail address, as appropriate. You acknowledge and agree that all communications sent to you at your email address on record will be treated as if they were delivered to you personally, whether or not you receive them.
</p>
</li>

<li style = {{fontWeight : 700}}>Section Headings

                        <p  style = {{fontWeight : 400}}>Section headings in this Agreement are for convenience of reference only and shall not govern the Urbanistpretation of any provision of this Agreement.</p>
                    </li>

                    <li style = {{fontWeight : 700}}>English Language Controls

<p  style = {{fontWeight : 400}}>Any translation of this Agreement is provided for your convenience. The meanings of terms, conditions and representations herein are subject to definitions and Urbanistpretations in the English language. Any translation provided may not accurately represent the information in the original English.</p>
</li>

<li style = {{fontWeight : 700}}>Entire Agreement

                        <p  style = {{fontWeight : 400}}>The Agreement, including the separate Privacy Policy, License Agreement and any other documents referred to herein, represents the entire understanding between both you and SDCL regarding the Services and the subject matter hereof and supersedes any prior statements or representations. In the event of a conflict between the terms of this Agreement and the terms of an exhibit, amendment, schedule, addendum or disclosure, the terms of the exhibit, amendment, schedule, addendum or disclosure shall prevail but, solely as to the subject matter herein.</p>
                    </li>


                  


                </ol>


                </p>
</div>
                </div>
            </Container>
        </div>
    )
}

export default TermsConditions
