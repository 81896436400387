import React from "react";
import { Container } from "react-bootstrap";

const PrivacyPolicy = () => {
  return (
    <div>
      <Container>
        <div
          style={{
            boxShadow: "0px 0px 15px -10px",
            padding: 40,
            margin: 20,
            textAlign: "center",
          }}
        >
          <a href="/" style={{ fontWeight: 700, textAlign: "center" }}>
            PRIVACY POLICY
          </a>
          <hr />

          <div
            style={{
              textAlign: "left",
            }}
          >
            <p
              style={{
                fontWeight: "bold",
              }}
            >
              INTRODUCTION
            </p>

            <p>
              Welcome to Juno Living! We work hard to protect your personal
              information and ensure your information is kept private and
              secure. This Privacy Policy outlines the type of personal
              information that Saunders Development Company Limited (“Saunders”)
              collects when operating the Juno Living Mobile Application
              (referred to as “the Platform”). Your privacy is extremely
              important to us. If you have any questions, please send us an
              email to hello@juno.africa
            </p>
            <p>
              This Privacy Policy governs the manner in which Saunders collects,
              uses, maintains and discloses information collected from users
              (each, a "User") of the Platform. This Privacy Policy applies to
              the all products and services offered on the Platform.
            </p>

            <p
              style={{
                fontWeight: "bold",
              }}
            >
              SUMMARY
            </p>

            <p>
              <p style={{ fontWeight: 500, textDecoration: "underline" }}>
                Information that we collect from you
              </p>
              <ul>
                <li>
                  We collect device-related information, server log information,
                  and other types of anonymous information when you visit the
                  Platform.
                </li>
                <li>
                  Additionally, we collect personal information and financial
                  information when you signup and use your account on the
                  Platform.
                </li>
              </ul>

              <p style={{ fontWeight: 500, textDecoration: "underline" }}>
                Why we collect your personal information
              </p>
              <ul>
                <li>
                  We use your personal information to maintain your account,
                  provide customer service, and enhance your customer
                  experience.
                </li>
                <li>
                  We use aggregate personal information and non-personal
                  information to improve the Platform and monitor and protect
                  the security of our service.
                </li>
              </ul>

              <p style={{ fontWeight: 500, textDecoration: "underline" }}>
                Who we share your personal information with
              </p>
              <ul>
                <li>
                  We do not sell, rent, or trade your personal information with
                  any third parties except as provided in this Privacy Policy,
                  with your consent, or as required by law.
                </li>
              </ul>

              <p
                style={{
                  fontWeight: "bold",
                }}
              >
                HOW YOU ACCEPT THIS POLICY
              </p>
              <p>
                By choosing to use the Platform, or otherwise providing us with
                Registration Information, you hereby consent to the processing
                of your Registration Information, this Privacy Policy and the
                terms and conditions for the use of the Platform.
              </p>

              <p
                style={{
                  fontWeight: "bold",
                }}
              >
                INFORMATION WE COLLECT AND WHY
              </p>
              <p>
                In order to provide a high-quality service, Saunders collects
                various types of information when you visit the Platform or
                sign-up as a User.
              </p>

              <p style={{ fontWeight: 500, textDecoration: "underline" }}>
                As a Visitor
              </p>

              <p>
                We collect various types of anonymous information about visitors
                to the Platform, such as device-related information (device ID,
                OS version) and server log information (date and time of app
                download). We also collect information that you input into the
                Platform. We use this information to better understand our
                visitors and our business and to enhance the Platform.
              </p>

              <p style={{ fontWeight: 500, textDecoration: "underline" }}>
                As a User
              </p>

              <p>
                When you choose to create an account on the Platform, we will
                also collect certain personal information such as:

                <ol type = 'a'>
                    <li>First Name</li>
                    <li>Last Name</li>
                    <li>Phone Number</li>
                    <li>Email</li>
                    <li>Password</li>
                </ol>
              </p>

             <p> We may also request for Know Your Customer (KYC) documents and details such as a valid identification card and your bank verification number, to confirm your identity. </p>

             <p>(Collectively known as <span style = {{fontWeight : 'bold'}}>“Registration Information”</span>.)</p>
            
             <p style={{ fontWeight: 500, textDecoration: "underline" }}>
             Non-Registration Information
              </p>

           <p>   We may collect non-registration information about Users whenever they Urbanistact with the Platform. Non-registration information may include device-related information (device ID, OS version), server log information (date and time of app download) technical information about Users means of connection to the Platform, Urbanistnet service providers utilized and other similar information.</p>
 
           <p
                style={{
                  fontWeight: "bold",
                }}
              > COOKIES & PIXELS</p>
<p> Cookies are small pieces of data that are stored on your computer, mobile phone or other device. Pixels are small code blocks on a website that allow for another server to measure viewing of a webpage and often are used in connection with cookies.</p>
 <p> We use these technologies in order to customize the Platform and improve your experience. Cookies store anonymous information, such as whether you viewed the Platform from your mobile device or tablet.  We may store some personal information in a cookie and/or pixel, such as the website that you visited immediately prior to visiting the Platform to allows us enhance your user experience and for marketing purposes. Additionally, we may use a cookie and/or that only stores anonymous information to track visitors across the Platform in order to better customize our marketing and advertising campaigns.</p>
 <p>Cookies, pixels, and similar technologies are not required for the Platform functionality. You are not required to accept any cookies or pixel to use the Platform. However, refusing to accept cookies or pixels will make the use of the Platform more cumbersome and less accessible.
 </p>  <p>You may choose to set your web browser to refuse cookies, or to alert you when cookies are being sent. If you do so, note that some parts of the Platform may not function properly.
 </p>
 <p
                style={{
                  fontWeight: "bold",
                }}
              >  HOW WE USE YOUR REGISTRATION INFORMATION </p>
<p>We use your registration information to enhance your customer experience, operate and maintain your account in accordance with Nigerian law, investigate and understand how the Platform is used, monitor and protect the security and integrity of the Platform, and better market and advertise our services.
</p><p>As part of this process, we may from time to time aggregate some of your personal information in certain data analysis, reports, or other Urbanistpretations of real estate trends for both Urbanistnal and external purposes. When aggregating personal information, we make sure that the information is not identifiable to any particular customer.
</p>
<p
                style={{
                  fontWeight: "bold",
                }}
              >WHEN WE SHARE YOUR INFORMATION</p>
<p>We do not sell, rent, or trade your registration information with any third parties without your consent or as required by law.
</p><p>In order to provide rental services, we may share your registration information with third parties, such as consumer identification verification services, for the purpose of serving you as a User. We have entered into agreements that require that these third parties keep your information confidential.
</p>We also may disclose some of the registration information we collect to service providers, companies that perform marketing and analytics services on our behalf.
<p>We may disclose registration information in response to service of legal process, such as a court order, summons, subpoenas, or as permitted or required by law when we reasonably believe it is necessary or appropriate to investigate, prevent, or take action regarding illegal activities, suspected fraud, frontrunning or scalping, situations involving potential threats to the physical safety of any person, or violations of our Terms of Use or customer agreements.
</p><p>Information about our users, including personal information, may be disclosed or transferred to entities now or in the future affiliated with Saunders or as part of any merger, acquisition, change of control, debt financing, insolvency, bankruptcy or sale of our assets. Such information may be used in the businesses of any entity so receiving it.
</p>
<p
                style={{
                  fontWeight: "bold",
                }}
              >CHILDREN</p>
<p>Our service is not directed towards anyone under the age of 18. If a parent or guardian becomes aware that his or her child under the age of 18 has created an account with Juno Living and/or provided us with personally identifiable information, please contact us at hello@juno.africa. 
</p><p>Additionally, if we become aware at any point that a child under the age of 18 is using our service, we will terminate his or her account.
</p>
<p  style={{
                  fontWeight: "bold",
                }}>HOW YOU CAN ACCESS OR CHANGE YOUR REGISTRATION INFORMATION THAT WE HAVE COLLECTED
</p>
<p>Once you have registered on Juno, you can access your profile, review the information that is stored, and revise that information. If you have any problems, you may also contact us at hello@juno.africa.
</p>
<p style={{
                  fontWeight: "bold",
                }}>PROTECTION OF REGISTRATION INFORMATION
</p>Juno Living works diligently to protect your registration information provided.  We employ several physical and electronic safeguards to keep your information safe. We use the strongest available browser encryption, store all of our data on servers in a secure facility, and implement systematic processes and procedures for securing and storing data.  We limit access to your personal and financial information to only those employees with authorized access, and we require third parties who perform services for Juno Living to agree to keep your information confidential. Furthermore, if you choose to deactivate your profile, we will continue to adhere to the privacy policies and practices outlined here.
<p>In addition, further to the Data Protection Regulation 2019, your Registration Information shall be: 
    <ul>
<li>collected and processed in accordance with specific, legitimate and lawful purpose as consented to by you; </li>
<li>adequate, accurate and without prejudice to the dignity of human person; </li>
<li>stored only for the period within which it is reasonably needed and </li>
<li>secured against all foreseeable hazards and breaches such as theft, cyberattack, viral attack, dissemination, manipulations of any kind, damage by rain, fire or exposure to other natural elements</li>
</ul>
</p>
<p style={{
                  fontWeight: "bold",
                }}>YOUR RIGHTS AS A USER</p>
<p>Users of the Platform possess the following rights:
    <ul>
<li>Right to request from Saunders access to and rectification or erasure of your Registration Information;</li>
<li>Right to withdraw consent at any time which shall not affect the processing of your Registration Information provided with consent before its withdrawal;</li>
<li>Right to lodge a complaint with the National Information Technology Development Agency (NITDA);</li>
<li>Right to object to the processing of your Registration Information for the purpose of marketing;</li>
<li>Right to request for the deletion of your Registration Information;</li>
<li>Right to request for your Registration Information which will be provided in a structured, commonly used and machine-readable format.</li>
</ul>
</p>
<p style={{
                  fontWeight: "bold",
                }}>THIRD PARTY WEBSITES</p>
<p>Users may find other content on our Platform that link to the sites and services of our partners, or other third parties. We do not control the content or links that appear on these sites and are not responsible for the practices employed by websites linked to or from the Platform. In addition, these sites or services, including their content and links, may be constantly changing. These sites and services may have their own privacy policies and customer service policies. Browsing and Urbanistaction on any other website, including websites which have a link to the Platform, is subject to that website's own terms and policies.
</p>
<p style={{
                  fontWeight: "bold",
                }}>ADVERTISING</p>
<p>Ads appearing on the Platform may be delivered to Users by advertising partners, who may set cookies. These cookies allow the ad server to recognize your mobile device each time they send you an online advertisement to compile non-registration information about you or others who use your mobile device. This information allows ad networks to, among other things, deliver targeted advertisements that they believe will be of most Urbanistest to you. This Privacy Policy does not cover the use of cookies by any advertisers
</p>
<p style={{
                  fontWeight: "bold",
                }}>CHANGES TO THE POLICY</p>
<p>We may modify this Privacy Policy over time. Any changes to the Privacy Policy will be reflected on this page and will become effective immediately upon posting. If the changes are significant, we will do our best to notify you via email or through a notification on the Platform or via email. Please check the effective date below to determine if there have been any changes since you have last reviewed the Juno Living Privacy Policy. You acknowledge and agree that it is your responsibility to review this Privacy Policy periodically and become aware of modifications.
</p>
<p style={{
                  fontWeight: "bold",
                }}>UrbanistNATIONAL VISITORS</p>
<p>This Service is hosted in Nigeria. If you are an Urbanistnational visitor, you should note that by providing your personal information, you are: (i) permitting the transfer of your personal information to Nigeria which may not have the same degree of data protection laws as the country in which you reside; and (ii) permitting the use of your personal information in accordance with this Privacy Policy.
</p>
<p style={{
                  fontWeight: "bold",
                }}>YOUR ACCEPTANCE OF THESE TERMS</p>
<p>By using the Platform, you signify your acceptance of this Privacy Policy. If you do not agree to this Privacy Policy, please do not sign up as a User on the Platform. Your continued use of the Platform following the posting of changes to this Privacy Policy will be deemed your acceptance of those changes.
</p>
<p style={{
                  fontWeight: "bold",
                }}>CONTACTING US</p>
<p>If you have any questions about this Privacy Policy, the practices of the Platform, or your dealings with the Platform, please contact us at hello@juno.africa.
</p>
            </p>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default PrivacyPolicy;
