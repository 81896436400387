import React from "react";
import { Col, Container, Row, Button } from "react-bootstrap";
import FacilitiesIcon from "./FacilitiesIcon";

const FacilitiesSection = () => {
  return (
    <Container id="features" style={{ paddingTop: 80, paddingBottom: 40 }}>
      <div id="row_custom">
        <div
          lg={6}
          md={12}
          sm={12}
          xs={12}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            width: "100%",
            justifyItems: "center",
          }}
          id="container_custom"
        >
          <p
            style={{
              fontFamily: "Urbanist",
              fontWeight: 400,
              fontSize: 16,
              margin: "auto",
              marginTop: 40,
              lineHeight: 1,
              color: "grey",
            }}
            className="text_edit"
            id="text-aligning-text"
          >
            More than just accommodation
          </p>
          <p
            style={{
              fontFamily: "Urbanist",
              fontWeight: 700,

              margin: "auto",
              marginTop: 10,
              lineHeight: 1,
            }}
            className=" mm-text "
            id="sub_text"
          >
            Welcome to Juno
          </p>

          <p
            style={{
              marginBottom: 50,
            }}
            className="sentence_para"
          >
            Home for Young Professionals where you can work, play and network
            all under one roof. Enjoy intentional designed co-living space where
            you can live your own way
          </p>

          <Button
            style={{
              width: "150px",

              borderRadius: "25px",
            }}
            variant="flat-outline"
            target="_blank"
            size="md"
            pill
            className="customButton"
            href="https://forms.zohopublic.com/saundersdev/form/JunoMembershipForm/formperma/8r3oS7B-9euyaMPW6LOBUf4JDXWh0b_1Bh17cipMN7Y"
          >
            Book a Room
          </Button>
        </div>

        <div className="img_box">
          <img
            alt="pic"
            src={
              "https://res.cloudinary.com/dan4b75j7/image/upload/v1719825523/Juno/juno-website/wjml6sf1b7qhwscvwmud.png"
            }
            className="img_Mob"
          />
          <img
            alt="pic"
            src={
              "https://res.cloudinary.com/dan4b75j7/image/upload/v1719825523/Juno/juno-website/ysmk3ihusgyfdo4h3ry5.png"
            }
            className="img_mob2"
          />
        </div>
      </div>
      <style type="text/css">
        {`


  img.d-block.w-100 {
    height: 100%;
    object-fit: cover;
  }

  .img_Mob {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  .img_box {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  #container_custom {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .sentence_para {
    max-width: 500px;
    padding-top: 30px;
    text-align: center;
  }

  .img_mob2 {
    display: none;
  }

  #sub_text {
    width: 100%;
    text-align: center;
  }

  .customButton {
    transform: translateY(30px);
    margin-bottom: 30px;
  }

  @media only screen and (min-width: 768px) {
    .rowEdit {
      display: flex;
    }

    .img_Mob {
      width: 80%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }

    .sentence_para {
      max-width: 600px;
    }
  }

  @media only screen and (min-width: 1024px) {
    div#row_custom {
      display: flex;
      align-items: center;
    }

    div#row_custom,
    .row_custom.row {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: center;
      align-items: center;
    }

    #container_custom {
      padding-bottom: 150px;
      align-items: flex-start;
    }

    #sub_text {
      width: 100%;
      text-align: left;
    }

    .sentence_para,
    .text_edit {
      width: 100%;
      text-align: left;
    }

    .row_custom.row {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: center;
      align-items: center;
    }

    div#row_custom {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
    }
  }

  @media only screen and (min-width: 1340px) {
    #features {
      max-width: 1000px;
      align-items: flex-start;
    }

    #container_custom {
      align-items: flex-start;
    }

    img.img_Mob {
      display: none;
    }

    .img_mob2 {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      display: block;
    }
  }



      `}
      </style>
    </Container>
  );
};

export default FacilitiesSection;
