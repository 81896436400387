import React, { useEffect, useState } from "react";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import "../css/Navbar.css";
import { List, X } from "react-bootstrap-icons";

const CustomNavbar = () => {
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  useEffect(() => {
    showButton();
  }, []);

  window.addEventListener("resize", showButton);

  return (
    <Navbar>
      <Container
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Navbar.Brand href="/#home">
          <img
            alt="hehe"
            width="116"
            height="40"
            className="d-inline-block align-top"
            src={process.env.PUBLIC_URL + "/assets/img/logo.png"}
          />
        </Navbar.Brand>
        <ul
          style={{
            paddingTop: 10,
            paddingBottom: 10,
          }}
          className={click ? "menu active" : "menu"}
        >
          <li>
            <Nav.Link
              className={"nav-link featureAlign ml-5"}
              href="/#features"
              onClick={closeMobileMenu}
            >
              Features
            </Nav.Link>
          </li>
          <li>
            <Nav.Link
              className={"nav-link ml-5"}
              href="/#how-it-works"
              onClick={closeMobileMenu}
            >
              How It Works
            </Nav.Link>
          </li>
          <li>
            {" "}
            <Nav.Link
              className={"nav-link ml-4"}
              href="/faqs"
              onClick={closeMobileMenu}
            >
              FAQs
            </Nav.Link>
          </li>
          <li>
            {" "}
            <Nav.Link
              className={"nav-link ml-4"}
              href="/about"
              onClick={closeMobileMenu}
            >
              About
            </Nav.Link>
          </li>
        </ul>
        <div style={{}} className="menu-btn " onClick={handleClick}>
          {click ? <X /> : <List />}
        </div>
      </Container>
    </Navbar>
  );
};

export default CustomNavbar;
