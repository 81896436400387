import React from "react";
import Col from "react-bootstrap/esm/Col";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import { FaInstagram, FaFacebook, FaLinkedin, FaYoutube } from "react-icons/fa";
import "../css/footer.css";

const Footer = () => {

  const currentYear = new Date().getFullYear();


  return (
    <footer style={{ backgroundColor: "#F3F5F8" }}>
      <Container>
        <Row style={{ paddingTop: 50 }}>
          <Col>
            <div style={{ marginTop: "20px" }}>
              <a href="/">
                <img
                  alt="hehe"
                  width="70"
                  height="24"
                  style={{ float: "left" }}
                  className="d-inline-block align-top"
                  src={process.env.PUBLIC_URL + "/assets/img/logo.png"}
                />
              </a>
            </div>

            <br />
            <div className="footer-text">
              <a href="/about">About Juno</a>
            </div>
            <div className="footer-text">
              <a href="/faqs">FAQs</a>
            </div>
          </Col>
          <Col>
            <div className="footer-text">
              <a href="/terms-and-conditions">Terms And Conditions</a>
            </div>
            <div className="footer-text">
              <a href="/privacy-policy">Privacy Policy</a>
            </div>
          </Col>
          <Col>
            {" "}
            <div className="footer-text">
              <a  href="tel:09048334926">0904 833 4926</a>
            </div>
            <div className="footer-text">
              <a href="mailto:hello@juno.africa">hello@juno.africa</a>
            </div>
            <div className="footer-text">33B, Cameron Road, Ikoyi, Lagos</div>{" "}
          </Col>
          <Col>
            <Row>
              <Col>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://web.facebook.com/Junolivingng/"
                >
                  <FaFacebook color="#144587" size={25} />
                </a>
              </Col>
              <Col>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.linkedin.com/company/juno-living/"
                >
                  <FaLinkedin color="#144587" size={25} />
                </a>
              </Col>
              <Col>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.instagram.com/juno.living/"
                >
                  <FaInstagram color="#144587" size={25} />
                </a>
              </Col>

              <Col>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.youtube.com/channel/UCczAaIH4uok1cf9UAOcdT-A"
                >
                  <FaYoutube color="#144587" size={25} />
                </a>
              </Col>
            </Row>
          </Col>
        </Row>
        <div className="footer-ribbon" style={{ textAlign: "center" }}>
      (C){currentYear}. All Rights Reserved Juno Africa
    </div>
      </Container>
    </footer>
  );
};

export default Footer;
