import React, { useEffect, useState } from 'react'
import CustomNavbar from '../CustomNavbar'
// import HeroScetion from "../HeroScetion";
import HeroSection from '../HeroSection'
import AvailableSection from '../AvailableSection'
import FacilitiesSection from '../FacilitiesSection'
import GetAJunoSection from '../GetAJunoSection'
import ContactSection from '../ContactSection'
import MobileStoreSection from '../MobileStoreSection'
import Footer from '../Footer'
import Modal from 'react-modal'
import { MyVerticallyCenteredModal } from '../CsModal'
// Rename react-bootstrap's Modal to BootstrapModal

const Home = () => {
  const [modalIsOpen, setIsOpen] = React.useState(false)

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      transform: 'translate(-50%, -50%)'
    }
  }

  function openModal() {
    setIsOpen(true)
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = "#f00";
  }

  function closeModal() {
    setIsOpen(false)
  }
  useEffect(() => {
    setTimeout(() => {
      openModal()
    }, 2000)
  }, [])

  const [modalShow, setModalShow] = React.useState(false)

  return (
    <>
      <style type="text/css">
        {`

.modal-content {
border-radius:20px;
}


.zstitlemini {
  font-family: 'Urbanist', sans-serif;
}
input{
  font-family: 'Urbanist', sans-serif;
}

input#captchaWord {
  font-family: 'Urbanist', sans-serif;

}

#zsfeedbackwidgetdiv .dt-blue-btn, #zsfeedbackwidgetdiv .blue-btn {
  color: #fff;
  background: #3b1f16;

  border: 1px solid #1A64AC;
}



.btn-30,
.btn-30 *,
.btn-30 :after,
.btn-30 :before,
.btn-30:after,
.btn-30:before {
  border: 0 solid;
  box-sizing: border-box;
  color:white !important;
}
.btn-30 {
  -webkit-tap-highlight-color: transparent;
  -webkit-appearance: button;
  background-color: #1A64AC;
  background-image: none;
  color: white;
  cursor: pointer;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont,
    Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif,
    Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: 100%;
  font-weight: 900;
  line-height: 1.5;
  margin: 0;
  // -webkit-mask-image: -webkit-radial-gradient(#000, #fff);
  padding: 0;
  text-transform: uppercase;
  color: white;
}

.btn-30:hover {
color: black !important;
}

.btn-30:disabled {
  cursor: default;
}
.btn-30:-moz-focusring {
  outline: auto;
}
.btn-30 svg {
  display: block;
  vertical-align: middle;
}
.btn-30 [hidden] {
  display: none;
}
.btn-30 {
  border-radius: 999px;
  // border-width: 1px;
  overflow: hidden;
  padding: 1rem 2rem;
  position: relative;
}
.btn-30 span {
  // mix-blend-mode: difference;
}
.btn-30:before {
  aspect-ratio: 1;
  background: #ffc107;
  border-radius: 50%;
  content: "";
  height: 0;
  left: 50%;
  position: absolute;
  top: -200%;
  transform: translateX(-50%);
  transition: height 0.3s;
  color:  blue;
}
.btn-30:hover:before {
  height: 400%;
}
.btn-30 .text-container {
  display: block;
  overflow: hidden;
  position: relative;
}
.btn-30 .text {
  display: block;
  // mix-blend-mode: difference;
  position: relative;
}
.btn-30:hover .text {
  -webkit-animation: move-up-alternate 0.3s ease forwards;
  animation: move-up-alternate 0.3s ease forwards;
}
@-webkit-keyframes move-up-alternate {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(80%);
  }
  51% {
    transform: translateY(-80%);
  }
  to {
    transform: translateY(0);
  }
}
@keyframes move-up-alternate {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(80%);
  }
  51% {
    transform: translateY(-80%);
  }
  to {
    transform: translateY(0);
  }
}

.modal-body {
  padding-bottom: 80px;
}

.modal{
  70%
}


  
      `}
      </style>
      {/* <ApartmentModal visible = {apartmentModalIsOpen} close = {closeApartmentModal}/> */}
      {/* <TourModal visible = {tourModalIsOpen} close = {closeTourModal}/> */}

      <CustomNavbar />
      {/* <HeroScetion
        openTourModal={openTourModal}
        openApartmentModal={openApartmentModal}
      /> */}
      <>
        <div style={{ position: 'fixed', bottom: '20px', right: '20px', zIndex: '999' }}>
          <button onClick={() => setModalShow(true)} class="btn-30">
            <span class="text-container">
              <span class="text">Send Message </span>
            </span>
          </button>
        </div>
        <MyVerticallyCenteredModal show={modalShow} onHide={() => setModalShow(false)} />
      </>
      <HeroSection />
      <AvailableSection />
      <FacilitiesSection />
      <GetAJunoSection />
      <MobileStoreSection />
      <ContactSection />
      <Footer />
      <Modal
        ariaHideApp={false}
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <h2>Flexible Payment</h2>
        <div className="close-modal" onClick={closeModal}>
          x
        </div>
        <p>
          We are now offering flexible payment plans. You can choose to pay for any of the options
          below:
        </p>
        <ul>
          <li>
            <span className="amount">N200,000</span>
            <span className="divider"></span>
            <span className="time">6 months</span>
          </li>
          <li>
            <span className="amount">N300,000</span>
            <span className="divider"></span>
            <span className="time">9 months</span>
          </li>
          <li>
            <span className="amount">N375,000</span>
            <span className="divider"></span>
            <span className="time">12 months</span>
          </li>
        </ul>
        <p>All fees are exclusive of 25k security deposit</p>
        <a href="https://forms.zohopublic.com/saundersdev/form/JunoMembershipForm/formperma/8r3oS7B-9euyaMPW6LOBUf4JDXWh0b_1Bh17cipMN7Y">
          Book Now
        </a>
      </Modal>
    </>
  )
}

export default Home
