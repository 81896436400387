import { Typography } from "@material-ui/core";
import React from "react";
import { Col, Container, Row, Button } from "react-bootstrap";
import CardCarousel from "./CardCarousel";

const AvailableSection = () => {
  return (
    <>
      <style type="text/css">
        {`


.rowEdit{
  display: flex;
    
  gap:0px;
}


     

     

      @media only screen and (min-width: 1340px) {
        .rowEdit{
          // gap:70px;
          display:flex;


      
      }

      .container, .container-lg, .container-md, .container-sm, .container-xl {
        max-width: 1400px;
    }

      @media only screen and (min-width: 600px) {
        
        .rowEdit{
          // gap: 70px;
        }
             

      }


      @media only screen and (max-width: 400px) {
       


      }

   
  
      `}
      </style>

      <div
        style={{
          backgroundColor: "#F4F8FB",
          justifyContent: "center",
          paddingBottom: "64px",
          paddingTop: "40px",
        }}
      >
        <Typography
          variant={"h4"}
          style={{
            textAlign: "center",
            paddingTop: "20px",
            fontFamily: "Urbanist",
            fontWeight: 700,
          }}
          className="mm-text"
        >
          Juno Facilities
        </Typography>
        <Typography
          variant={"h4"}
          style={{
            fontWeight: "400",
            textAlign: "center",
            fontSize: 20,
            letterSpacing: 0,
            fontFamily: "Urbanist",

            paddingTop: "2px",
          }}
        >
          A home for young innovators
        </Typography>
        <Container>
          <Row
            className="rowEdit"
            style={{
              paddingTop: 40,
              // paddingBottom: 40,
            }}
          >
            <Col className="pt-2">
              <CardCarousel
                header="Juno Karimu Ikotun"
                subtitle="A fine blend of luxury and affordable living"
                images={[
                  "https://res.cloudinary.com/dan4b75j7/image/upload/v1720605055/Juno/juno-website/kdp8vjfya8brncmqgtpm.jpg",
                  "https://res.cloudinary.com/dan4b75j7/image/upload/v1720600396/Juno/juno-website/zyawfdqwyszlxojtj1lc.jpg",

                  "https://res.cloudinary.com/dan4b75j7/image/upload/v1720600391/Juno/juno-website/e7vifivnrmwjc3araoud.jpg",

                  "https://res.cloudinary.com/dan4b75j7/image/upload/v1720600391/Juno/juno-website/oyul417e8ljslp3kpp42.jpg",
                  "https://res.cloudinary.com/dan4b75j7/image/upload/v1720600391/Juno/juno-website/rztnvuaoqxstnm3rty6h.jpg",
                  "https://res.cloudinary.com/dan4b75j7/image/upload/v1720600392/Juno/juno-website/p51jfxvxvuukn1uwapvm.jpg",
                ]}
                // icons={["assets/img/b (1).svg"]}
                location="Sabo, Yaba"
                link="https://maps.app.goo.gl/P7QguJPZv31kcytA6"
                page="/"
                nameRoute="Book Now"
                linkRoute="https://forms.zohopublic.com/saundersdev/form/JunoMembershipForm/formperma/8r3oS7B-9euyaMPW6LOBUf4JDXWh0b_1Bh17cipMN7Y"
              />
            </Col>
            <Col className="pt-2">
              <CardCarousel
                header="Juno Emily Akinola"
                subtitle="Comfy and Affordable Studio and Shared Rooms (All Ensuite)"
                images={[
                  // "assets/img/juno-lite/juno-lite.png",
                  // "assets/img/juno-lite/juno-yaba2.jpg",
                  // "assets/img/juno-lite/juno-yaba9.jpg",
                  // "assets/img/juno-lite/juno-yaba8.jpg",
                  // "assets/img/juno-lite/juno-yaba1.jpg",
                  // "assets/img/juno-lite/juno-yaba5.jpg",
                  // "assets/img/juno-lite/juno-yaba4.jpg",
                  // "assets/img/juno-lite/juno-yaba6.jpg",
                  // "assets/img/juno-lite/juno-yaba5.jpg",

                  "assets/img/juno-lite/juno-yaba1.jpg",
                  "assets/img/juno-lite/juno-yaba5.jpg",
                  "assets/img/juno-lite/juno-yaba6.jpg",
                  "assets/img/juno-lite/juno-yaba2.jpg",
                  "assets/img/juno-lite/juno-yaba8.jpg",
                  "assets/img/juno-lite/juno-yaba4.jpg",
                ]}
                // icons={["assets/img/b (1).svg"]}
                location="Akoka, Yabo"
                link="https://goo.gl/maps/Qnh2KNN8cucYnBze8"
                page="/juno-lite"
                nameRoute="Book Now"
                linkRoute="https://forms.zohopublic.com/saundersdev/form/JunoMembershipForm/formperma/8r3oS7B-9euyaMPW6LOBUf4JDXWh0b_1Bh17cipMN7Y"
                // pricing={"₦375,000 "}
              />
            </Col>
            <Col className="pt-2 thirdCard">
              {/* <CardCarousel header = "Juno Essential" subtitle = "En-suite unit (Room + Bathroom)" images = {["assets/img/Picture1.jpg","assets/img/Picture2.jpg", "assets/img/Picture3.jpg", "assets/img/Picture4.jpg", "assets/img/Picture5.jpg"]}icons = {["assets/img/b.svg", "assets/img/b (2).svg"]} location = "Bwari, Abuja" link = 'https://goo.gl/maps/Yyq9VQQeBBnzMhEB8' page = '#'/> */}
              <CardCarousel
                header="Juno Bwari"
                subtitle="En-suite units for privacy and comfort"
                images={[
                  "assets/img/juno-essential/juno-living-1.jpeg",
                  "assets/img/juno-essential/juno-living-3.jpeg",
                  "assets/img/juno-essential/juno-living-2.jpeg",

                  "assets/img/juno-essential/juno-living-essentials-bwari-4.jpg",
                  "assets/img/juno-essential/juno-living-essentials-bwari-6.jpg",
                  "assets/img/juno-essential/juno-living-essentials-bwari-2.jpg",
                  "assets/img/juno-essential/juno-living-essentials-bwari-3.jpg",
                ]}
                // icons={["assets/img/b.svg", "assets/img/b (2).svg"]}
                location="Bwari, Abuja"
                link="https://goo.gl/maps/X58QCKEqYvxBq8zV8"
                page="/juno-essential"
                // pricing={"Starting from ₦800,000 "}
                nameRoute="Book Now"
                linkRoute="https://forms.zohopublic.com/saundersdev/form/JunoMembershipForm/formperma/8r3oS7B-9euyaMPW6LOBUf4JDXWh0b_1Bh17cipMN7Y"
              />
            </Col>
            <Col className="pt-2">
              <CardCarousel
                header="Juno Karimu Ikotun"
                subtitle="Coming soon"
                images={[
                  "https://res.cloudinary.com/dan4b75j7/image/upload/v1714671997/Juno/juno-website/wmozfkdnamx1ja2xj6t4.png",
                ]}
                // icons={["assets/img/b (1).svg"]}
                location="Sabo, Yaba"
                link="https://maps.app.goo.gl/P7QguJPZv31kcytA6"
                page="/"
                nameRoute="Enquire Now"
                linkRoute="https://docs.google.com/forms/d/e/1FAIpQLSdJLH4A3y-vUU233NW8F0F1fo3eREZ3Mp1jMtnAZaKdJ-ot7Q/viewform"
              />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default AvailableSection;
