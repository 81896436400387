import React from 'react'

const Steps = (props) => {
  return (
    <div style={{ textAlign: 'center', padding: 20 }}>
      <div>
        <img src={props.img} alt={'pic'} height={128} />
      </div>
      <p
        style={{
          fontFamily: 'Urbanist',
          fontSize: 30,
          fontWeight: 600,
          marginTop: 20
        }}
      >
        {props.title}
      </p>
      <p
        style={{
          fontFamily: 'Urbanist',
          fontSize: 14,
          fontWeight: 400
        }}
      >
        {props.text}{' '}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={props.url}
          style={{ marginLeft: 1, textDecoration: 'none' }}
          className="link"
        >
          {props.urlText}
        </a>
        <style>{`
        .link {
            margin-left: 2px;
            color: #144587;
            text-decoration: none;
          }
          
          .link:hover {
            color: #3C6DAF;
          }
        `}</style>
      </p>
    </div>
  )
}

export default Steps
