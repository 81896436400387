/* global window.gapi */
import { Input } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { Alert, Button, Col, Container, Form, FormLabel, InputGroup, Row } from 'react-bootstrap'

import swal from 'sweetalert'

const ContactSection = () => {
  const [formData, setFormData] = useState({ fullname: '', email: '' })

  const handleInput = (e) => {
    const copyFormData = { ...formData }
    copyFormData[e.target.name] = e.target.value
    setFormData(copyFormData)
  }

  const [form, setForm] = useState({})

  const scriptURL =
    'https://script.google.com/macros/s/AKfycbxe1LlOMu4Xv0RkyRbfxaJk2E5s6ZxHXbo99hhY02g1asHyF7pUPLAamKeyjZphQokO/exec'

  const onFormSubmit = async (e) => {
    e.preventDefault()

    const { fullname, email } = formData

    var myformData = new FormData()

    myformData.set('Name', fullname)

    myformData.set('Email', email)

    fetch(scriptURL, { method: 'POST', body: myformData })
      .then((response) => {
        swal({
          title: 'Delivered',
          text: "We'll get in touch with you",
          icon: 'success'
        })

        document.getElementById('contact-form').reset()
      })
      .catch((error) => {
        swal({
          title: 'Oops',
          text: 'Something went wrong',
          icon: 'error'
        })

        document.getElementById('contact-form').reset()
      })
  }

  return (
    <>
      <style type="text/css">
        {`

        .form-div {
            padding-right : 180px;
            padding-left : 180px;
        }

        .form-section {
          padding : 50px;
      }



        @media only screen and (max-width: 600px) {
          .form-div {
            padding-right : 0px;
            padding-left : 0px;
          }

          .form-section {
            padding : 50px 0px 50px 0px;
        }
        }

        .contact-text { 
          font-family : "Urbanist";
          font-weight : 500;
          textAlign : left;
          font-size : 13px;
        }

        @media only screen and (max-width: 1200px) {
          .form-div {
            padding-right : 0px;
            padding-left : 0px;
          }

          .form-section {
            padding : 50px 0px 50px 0px;
        }
        }
    

    
        `}
      </style>

      <div
        className="form-section"
        style={{ textAlign: 'center', backgroundColor: '#FAFAF5' }}
        id="contact"
      >
        <Container>
          <div style={{}}>
            <p style={{ fontFamily: 'Urbanist', fontWeight: 700, fontSize: 30 }}>
              2024 New Juno Alert{' '}
            </p>
            <p style={{ fontFamily: 'Urbanist', fontWeight: 500, fontSize: 13 }}>
              Exciting News! Experience the All-New Juno in Sabo Yaba!
              <br />
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={
                  'https://forms.zohopublic.com/saundersdev/form/JunoMembershipForm/formperma/8r3oS7B-9euyaMPW6LOBUf4JDXWh0b_1Bh17cipMN7Y'
                }
                style={{ marginLeft: 1, textDecoration: 'none' }}
                className="link"
              >
                Bookings Open Now
              </a>
            </p>
            <div style={{ padding: 10 }}>
              <Form id="contact-form" onSubmit={onFormSubmit}>
                <Row className="form-div">
                  <Col lg={6} md={6}>
                    <Form.Group style={{ textAlign: 'left' }} className="mb-3">
                      <Form.Label className="contact-text">Full name</Form.Label>

                      <Form.Control
                        name="fullname"
                        type="text"
                        placeholder="First name    Last name"
                        onChange={handleInput}
                      />
                    </Form.Group>
                  </Col>

                  <Col lg={6} md={6}>
                    <Form.Group style={{ textAlign: 'left' }} className="mb-3">
                      <Form.Label className="contact-text">Email address</Form.Label>

                      <Form.Control
                        type="email"
                        name="email"
                        placeholder="yourname@example.com"
                        onChange={handleInput}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row className="form-div">
                  <Col lg={6} md={6}>
                    <style type="text/css">
                      {`
    .btn-sub {
      background-color: #1A64AC;
      color: white;
      transition: all .2s ease-in-out;
    }

    .btn-sub:hover {

        color: white;
        transform : scale(1.05);
      }

    `}
                    </style>

                    <Button variant="sub" type="submit" style={{ marginTop: 35, width: '100%' }}>
                      Let me know
                    </Button>
                  </Col>
                </Row>
              </Form>
            </div>
          </div>
        </Container>
      </div>
      <style>{`
        .link {
            margin-left: 2px;
            color: #144587;
            text-decoration: none;
          }
          
          .link:hover {
            color: #3C6DAF;
          }
        `}</style>
    </>
  )
}

export default ContactSection
