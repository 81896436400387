import React from "react";

const FacilitiesIcon = (props) => {
  return (
    <div
      style={{
        marginTop: 20,
        margin: 10,
    
      }}
    >
      <div
        style={{
         
          height: props.height,
          textAlign:'center',
          borderWidth : 2,
          borderColor : 'black'
        }}
      >
        <img style={{margin: 5 }} src={props.icon} />
      </div>

      
     
      <div>
      <p
        style={{
          fontFamily: "Lato",
          fontWeight: 700,
          color: "#144587",
          fontSize: 14,
          textAlign: "center",
        }}
      >
        {props.title}
      </p>
      </div>

      
    </div>
  );
};

export default FacilitiesIcon;
