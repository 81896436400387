import React from "react";
import { Col, Container, Row } from "react-bootstrap";

const MobileStoreSection = () => {
  return (
    <>
      {" "}
      <Container>
        <p
          className="mm-text"
          style={{
            marginTop: 60,
            fontFamily: "Urbanist",
            fontWeight: 700,
            textAlign: "center",
          }}
        >
          {" "}
          Coming Soon On Mobile
        </p>
      </Container>
      <div
        style={{
          paddingTop: 0,
          //  paddingBottom: 100
        }}
        className="whiteOrientation"
      >
        <Container
          style={{
            backgroundColor: "#EDF5FA",
            padding: 20,
            borderRadius: 10,
            paddingBottom: 90,
          }}
        >
          <Row
            style={{
              width: "100%",
            }}
          >
            <style type="text/css">
              {`

        .mm-text , .mm-text_content {
            font-size : 28px;
            text-align : center;
        }

        .imageOrientation{
            display: flex;
            align-items: center;
            justify-content: center;
            transform: translateY(513px);
        
        }

        .whiteOrientation{
            padding-bottom:390px
        }


        .device-image {
            transform : scale(0.8);
        }
       

        .device-image {
            height: 525px;
            
            transform : scale(0.8);
            transform : translate(20px, -60px);
           
        }
.device-image {
                transform : scale(0.8);
            }

            @media only screen and (min-width: 480px) {

                .imageOrientation{
                  
                    transform: translateY(438px);
                
                }

            }

        @media only screen and (min-width: 600px) {
            .mm-text , .mm-text_content {
                font-size : 27px;
            }

            .device-image {
                // visibility: hidden;
            }

            .imageOrientation{
                display: flex;
                align-items: center;
                justify-content: center;
                transform: translateY(367px);
            
            }
    
            .whiteOrientation{
                padding-bottom:410px
            }
          }


          @media only screen and (min-width: 768px) {

            .imageOrientation{
            justify-content: center;
            transform: translateY(0px);
            align-items: normal;
            }

            .mm-text {
                font-size : 40px;
                text-align: left;

            }

            .mm-text_content{
                text-size : 27px;
            }

            .whiteOrientation {
                padding-bottom: 230px;
            }

          }


          @media only screen and (min-width: 992px) {

           

            .whiteOrientation {
                padding-bottom: 280px;
            }

          }

          @media only screen and (min-width: 1200px) {
          

            .device-image {
                transform : scale(0.8);
            }

            .imageOrientation {
                display: flex;
                align-items: normal;
                justify-content: center;
                /* transform: translateY(450px); */
            }
            .whiteOrientation {
                padding-bottom: 280px;

            }

          }


          @media only screen and (max-width: 770px) {
          

            .device-image {
                // visibility: hidden;
            }
          }
    
    
        `}
            </style>

            <Col>
              <div style={{ height: "20px" }} className="imageOrientation">
                <img
                  className="device-image"
                  src="assets/img/juno in phones-min.png"
                />
              </div>
            </Col>
            <Col>
              <p
                className="mm-text_content"
                style={{
                  marginTop: 60,
                  fontFamily: "Urbanist",
                  fontWeight: 500,
                  lineHeight: "normal",
                  textSizeAdjust: "small",
                  width: "100%",
                }}
              >
                Elevate your living experience with the upcoming Juno Residence
                mobile app!
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default MobileStoreSection;
