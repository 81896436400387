import React from "react";
import Home from "./components/src/Pages/Home";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import About from "./components/src/Pages/About";
import FAQs from "./components/src/Pages/FAQs";
import TermsConditions from "./components/src/Pages/TermsConditions";
import JunoLite from "./components/src/Pages/JunoLite";
import JunoEssential from "./components/src/Pages/JunoEssential";
import PrivacyPolicy from "./components/src/Pages/PrivacyPolicy";
import JunoKarimu from "./components/src/Pages/JunoKarimu";

const App = () => {
  return (
    <>
      <Router>
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/about" exact component={About} />
          <Route path="/faqs" exact component={FAQs} />
          <Route
            path="/terms-and-conditions"
            exact
            component={TermsConditions}
          />
          <Route path="/juno-lite" exact component={JunoLite} />
          <Route path="/privacy-policy" exact component={PrivacyPolicy} />
          <Route path="/juno-essential" exact component={JunoEssential} />
          <Route path="/juno-karimu" exact component={JunoKarimu} />
          <Route
            path="/maintenance"
            component={() => {
              window.location.href = "https://zfrmz.com/lP70i5eh2z3OqsSRhOvS";
              return null;
            }}
          />
        </Switch>
      </Router>
    </>
  );
};

export default App;
