import React, { useEffect, useState } from "react";
import { Container, Form } from "react-bootstrap";
import Faq from "react-faq-component";
import CustomNavbar from "../CustomNavbar";
import Footer from "../Footer";
import ContactSection from "../ContactSection";
import MobileStoreSection from "../MobileStoreSection";
import { MyVerticallyCenteredModal } from "../CsModal";

const data = {
  rows: [
    {
      title: "Who is Juno Living for?",
      content: `Juno Social Living was thoughtfully crafted for the unique needs of young professionals in Nigeria. Our communities are designed to help you thrive, with affordable housing, convenient amenities, and a supportive community.
            `,
    },
    {
      title: "What is social living?",
      content: `Social living is a vibrant community living concept that unites like-minded individuals to coexist, collaborate, and engage in life's experiences as a collective. Residents share thoughtfully maintained communal areas and amenities.`,
    },
    {
      title: "Where is Juno located? ",
      content: `Currently, Juno is located at 43/45 Karimu Ikotun Williams Close, Oyadiran Estate, Sabo, Yaba; Emily Akinola Street, Off St Finbars College Road, Akoka, Yaba and Bwari, Abuja (directly opposite the Nigerian Law School entrance gate). `,
    },

    {
      title: "What types of accommodations does Juno offer?",
      content: `Juno offers a variety of accommodations, including cozy studios, interactive doubles, and lively quads with shared spaces.`,
    },

    {
      title: "What are the perks of living at Juno?",
      content: `Juno provides flexible payment plans, a central location, community events, and various amenities like a gym, restaurant, and more.`,
    },
    {
      title: "What are the facilities available?",
      content: `We provide a range of facilities including:
           <br/> • 24/7 Dedicated site security personnel
           <br/> • 24/7 Blended Power (Solar/Public Mains/Generator)
           <br/> • In-house Restaurant & Groceries
           <br/>• TV Rooms and Lounges.
           <br/>• Games Room
           <br/>• Reading Rooms
           <br/>• 24/7 CCTV coverage
           <br/>• Laundry & Cleaning service subject to availability at each location.
           <br/>• Clean Water  `,
    },

    {
      title: "Can I choose my roommates?",
      content: `No, rooms are allocated on a first come basis.  
            `,
    },

    {
      title: "What is included in the monthly rent?",
      content: `The monthly rent covers accommodation, utilities, and access to shared amenities. Additional services may have separate charges.`,
    },

    {
      title: "What are the durations available?",
      content: `we have different durations with flexible payment options`,
    },

    {
      title: "Is there on-site parking?",
      content: `Reserved parking is available subject to the location. Parking fees may apply please enquire with the Community Manager.`,
    },

    {
      title: "Is Juno pet-friendly?",
      content: `Unfortunately, at the moment, Juno is not pet-friendly.`,
    },

    {
      title: "How does the Juno waitlist work?",
      content: `To join the Juno waitlist, visit our website and fill in your details. You'll be notified when a space becomes available.`,
    },

    {
      title: "What security measures are in place at Juno?",
      content: `Juno prioritizes the safety of its residents with secure access system, CCTV surveillance  and,  on-site security.`,
    },
    {
      title: "Can I host guests at Juno?",
      content: `Yes, residents are allowed to have guests. However, there might be guidelines 
            for guest sleepovers in place depending on the type of room a member occupies.
           `,
    },

    {
      title: "What happens if I need maintenance in my room?",
      content: `Juno has a responsive maintenance team. You can send maintenance requests via email: hello@juno.africa.com.`,
    },

    {
      title: "What happens if I need to move out before my lease ends?",
      content: `Contact our team, and we can discuss your situation. Terms and conditions for early termination may apply.`,
    },

    {
      title: "Do I need to pay any security deposit/maintenance fee?",
      content: `A refundable security deposit is required before moving in.
            `,
    },
    {
      title: "How do I move in?",
      content: `That's the easy part! Once you’ve been accepted and confirmed your ideal moving-in date, we’ll be there to welcome you as a Juno Living member. We’ll help you settle in your room, show you around the space, and introduce you to the other Juno Living members. 
            <br/> Your room/bed space should be occupied within one month of payment.
            `,
    },

    {
      title: "How can I contact you?",
      content: ` Contact us via email: hello@juno.africa.com
            `,
    },
  ],
};

const styles = {
  // bgColor: 'white',
  titleTextColor: "#002159",
  rowTitleColor: "#002159",

  // rowContentColor: 'grey',
  // arrowColor: "red",
};

const config = {
  // animate: true,
  // arrowIcon: "V",
  // tabFocus: true
};

const FAQs = () => {
  const [modalShow, setModalShow] = React.useState(false);

  return (
    <>
      <style type="text/css">
        {`

.modal-content {
border-radius:20px;
}


.zstitlemini {
  font-family: 'Urbanist', sans-serif;
}
input{
  font-family: 'Urbanist', sans-serif;
}

input#captchaWord {
  font-family: 'Urbanist', sans-serif;

}

#zsfeedbackwidgetdiv .dt-blue-btn, #zsfeedbackwidgetdiv .blue-btn {
  color: #fff;
  background: #3b1f16;

  border: 1px solid #1A64AC;
}



.btn-30,
.btn-30 *,
.btn-30 :after,
.btn-30 :before,
.btn-30:after,
.btn-30:before {
  border: 0 solid;
  box-sizing: border-box;
  color:white !important;
}
.btn-30 {
  -webkit-tap-highlight-color: transparent;
  -webkit-appearance: button;
  background-color: #1A64AC;
  background-image: none;
  color: white;
  cursor: pointer;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont,
    Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif,
    Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: 100%;
  font-weight: 900;
  line-height: 1.5;
  margin: 0;
  // -webkit-mask-image: -webkit-radial-gradient(#000, #fff);
  padding: 0;
  text-transform: uppercase;
  color: white;
}

.btn-30:hover {
color: black !important;
}

.btn-30:disabled {
  cursor: default;
}
.btn-30:-moz-focusring {
  outline: auto;
}
.btn-30 svg {
  display: block;
  vertical-align: middle;
}
.btn-30 [hidden] {
  display: none;
}
.btn-30 {
  border-radius: 999px;
  // border-width: 1px;
  overflow: hidden;
  padding: 1rem 2rem;
  position: relative;
}
.btn-30 span {
  // mix-blend-mode: difference;
}
.btn-30:before {
  aspect-ratio: 1;
  background: #ffc107;
  border-radius: 50%;
  content: "";
  height: 0;
  left: 50%;
  position: absolute;
  top: -200%;
  transform: translateX(-50%);
  transition: height 0.3s;
  color:  blue;
}
.btn-30:hover:before {
  height: 400%;
}
.btn-30 .text-container {
  display: block;
  overflow: hidden;
  position: relative;
}
.btn-30 .text {
  display: block;
  // mix-blend-mode: difference;
  position: relative;
}
.btn-30:hover .text {
  -webkit-animation: move-up-alternate 0.3s ease forwards;
  animation: move-up-alternate 0.3s ease forwards;
}
@-webkit-keyframes move-up-alternate {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(80%);
  }
  51% {
    transform: translateY(-80%);
  }
  to {
    transform: translateY(0);
  }
}
@keyframes move-up-alternate {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(80%);
  }
  51% {
    transform: translateY(-80%);
  }
  to {
    transform: translateY(0);
  }
}

.modal-body {
  padding-bottom: 80px;
}

.modal{
  70%
}


  
      `}
      </style>
      <>
        <div
          style={{
            position: "fixed",
            bottom: "20px",
            right: "20px",
            zIndex: "999",
          }}
        >
          <button onClick={() => setModalShow(true)} class="btn-30">
            <span class="text-container">
              <span class="text">Send Message </span>
            </span>
          </button>
        </div>
        <MyVerticallyCenteredModal
          show={modalShow}
          onHide={() => setModalShow(false)}
        />
      </>
      <CustomNavbar />
      <div>
        <Container>
          {" "}
          <style type="text/css">
            {`

        .faq-header {
            padding : 125px;
        }

        .faq { 
            padding : 100px;
        }

        @media only screen and (max-width: 600px) {
            .faq-header {
                padding : 30px 5px 30px 5px;
               
               
            }

            .faq { 
                padding : 10px;
            }
          }

          .row-title {

            font-family : 'Urbanist', sans-serif;
            font-weight : 600;
            text-align : left;
        

          }

                        
        .row-content-text {
            
            font-family : 'Urbanist', sans-serif;
            text-align : left;
            font-size : 16px !important;
            font-weight : 400;
            color: #616E7C !important;
         
            
        }
             
    

          
    
        `}
          </style>
          <div
            className="faq-header"
            style={{
              backgroundImage: "linear-gradient(90deg, #144586, #7BB4E8)",
              borderRadius: "11px",
              textAlign: "center",

              marginBottom: "40px",
              marginTop: "20px",
            }}
          >
            <p
              style={{
                fontFamily: "Urbanist",
                fontSize: "40px",
                fontWeight: 600,
                color: "white",
              }}
            >
              Frequently Asked Questions
            </p>

            {/* <Form.Control style ={{margin : 'auto', width : 300, }} placeholder="Search for questions..." /> */}
          </div>
          <div
            className="faq"
            style={{
              textAlign: "center",
            }}
          >
            <Faq data={data} styles={styles} config={config} />
          </div>
        </Container>
      </div>
      <MobileStoreSection />
      <ContactSection />
      <Footer />
    </>
  );
};

export default FAQs;
