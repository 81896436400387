import { Col, Container, Row } from 'react-bootstrap'
import BackedInfo from '../BackedInfo'
import CustomNavbar from '../CustomNavbar'
import Footer from '../Footer'
import ContactSection from '../ContactSection'
import MobileStoreSection from '../MobileStoreSection'
import React, { useEffect, useState } from 'react'
import { MyVerticallyCenteredModal } from '../CsModal'

const About = () => {
  const [modalShow, setModalShow] = React.useState(false)
  const [modalIsOpen, setIsOpen] = React.useState(false)

  return (
    <>
      <style type="text/css">
        {`

.modal-content {
border-radius:20px;
}


.zstitlemini {
  font-family: 'Urbanist', sans-serif;
}
input{
  font-family: 'Urbanist', sans-serif;
}

input#captchaWord {
  font-family: 'Urbanist', sans-serif;

}

#zsfeedbackwidgetdiv .dt-blue-btn, #zsfeedbackwidgetdiv .blue-btn {
  color: #fff;
  background: #3b1f16;

  border: 1px solid #1A64AC;
}



.btn-30,
.btn-30 *,
.btn-30 :after,
.btn-30 :before,
.btn-30:after,
.btn-30:before {
  border: 0 solid;
  box-sizing: border-box;
  color:white !important;
}
.btn-30 {
  -webkit-tap-highlight-color: transparent;
  -webkit-appearance: button;
  background-color: #1A64AC;
  background-image: none;
  color: white;
  cursor: pointer;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont,
    Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif,
    Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: 100%;
  font-weight: 900;
  line-height: 1.5;
  margin: 0;
  // -webkit-mask-image: -webkit-radial-gradient(#000, #fff);
  padding: 0;
  text-transform: uppercase;
  color: white;
}

.btn-30:hover {
color: black !important;
}

.btn-30:disabled {
  cursor: default;
}
.btn-30:-moz-focusring {
  outline: auto;
}
.btn-30 svg {
  display: block;
  vertical-align: middle;
}
.btn-30 [hidden] {
  display: none;
}
.btn-30 {
  border-radius: 999px;
  // border-width: 1px;
  overflow: hidden;
  padding: 1rem 2rem;
  position: relative;
}
.btn-30 span {
  // mix-blend-mode: difference;
}
.btn-30:before {
  aspect-ratio: 1;
  background: #ffc107;
  border-radius: 50%;
  content: "";
  height: 0;
  left: 50%;
  position: absolute;
  top: -200%;
  transform: translateX(-50%);
  transition: height 0.3s;
  color:  blue;
}
.btn-30:hover:before {
  height: 400%;
}
.btn-30 .text-container {
  display: block;
  overflow: hidden;
  position: relative;
}
.btn-30 .text {
  display: block;
  // mix-blend-mode: difference;
  position: relative;
}
.btn-30:hover .text {
  -webkit-animation: move-up-alternate 0.3s ease forwards;
  animation: move-up-alternate 0.3s ease forwards;
}
@-webkit-keyframes move-up-alternate {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(80%);
  }
  51% {
    transform: translateY(-80%);
  }
  to {
    transform: translateY(0);
  }
}
@keyframes move-up-alternate {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(80%);
  }
  51% {
    transform: translateY(-80%);
  }
  to {
    transform: translateY(0);
  }
}

.modal-body {
  padding-bottom: 80px;
}

.modal{
  70%
}


  
      `}
      </style>
      <CustomNavbar />
      <div>
        <div
          style={{
            backgroundColor: 'rgba(171, 184, 198, 0.6)',
            paddingTop: 100,
            paddingBottom: 100
          }}
        >
          <Container>
            <div style={{ textAlign: 'center' }}>
              <p
                style={{
                  fontFamily: 'Urbanist',
                  fontWeight: 400,
                  fontSize: 43,
                  color: '#002159'
                }}
              >
                Who We Are
              </p>
              <p
                style={{
                  fontFamily: 'Urbanist',
                  fontWeight: 500,
                  fontSize: 20,
                  color: 'black'
                }}
              >
                Juno is a vibrant social-living community for young professionals, offering
                affordable housing and amenities designed to support your journey to success.
              </p>
            </div>
          </Container>
        </div>

        <Container>
          <div style={{ textAlign: 'center', paddingTop: 75 }}>
            <p
              style={{
                fontFamily: 'Urbanist',
                fontWeight: 600,
                fontSize: 30
              }}
            >
              Our Mission
            </p>

            <p
              style={{
                fontFamily: 'Urbanist',
                fontWeight: 400,
                fontSize: 20,
                textAlign: 'left',
                color: '#616E7C'
              }}
            >
              Juno Social Living is driven by a singular purpose: to take the leading role in
              addressing the challenge of providing accessible and affordable living communities for
              young professionals.
            </p>

            <p
              style={{
                fontFamily: 'Urbanist',
                fontWeight: 600,
                fontSize: 30,
                marginTop: 95
              }}
            >
              Our Goals
            </p>

            <p
              style={{
                fontFamily: 'Urbanist',
                fontWeight: 400,
                fontSize: 20,
                textAlign: 'left',
                color: '#616E7C'
              }}
            >
              Making a better life attainable for young African professionals through functional
              communities where they can thrive.
            </p>

            {/* <p
            style={{
              fontFamily: "Urbanist",
              fontWeight: 600,
              fontSize: 32,
              marginTop : 95,
              marginBottom : 75,
     
            }}
          >
            Backed By
          </p>

          <Row>

          <Col style = {{marginTop : 50}}>
              <BackedInfo image = {'assets/img/sankore logo_dark.png'} title = {'Sankore Investments'} name = {''}/>
              </Col>

              <Col  style = {{marginTop : 50}}>
              <BackedInfo image = {'assets/img/Saunders logo.png'} title = {'Sunders Development Company'} name = {""}/>
              </Col>
              
          
          </Row> */}
          </div>
        </Container>
      </div>
      <MobileStoreSection />
      <ContactSection />
      <Footer />
      <>
        <div style={{ position: 'fixed', bottom: '20px', right: '20px', zIndex: '999' }}>
          <button onClick={() => setModalShow(true)} class="btn-30">
            <span class="text-container">
              <span class="text">Send Message </span>
            </span>
          </button>
        </div>
        <MyVerticallyCenteredModal show={modalShow} onHide={() => setModalShow(false)} />
      </>
    </>
  )
}

export default About
