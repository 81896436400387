import React from "react";
import { Col, Row, Container, Button } from "react-bootstrap";
// import ApartmentModal from "../ApartmentModal";
import CustomNavbar from "../CustomNavbar";
import FacilitiesIcon from "../FacilitiesIcon";

const JunoKarimu = () => {
  const [
    //   apartmentModalIsOpen,
    // setApartmentModalIsOpen
  ] = React.useState(false);

  // function openApartmentModal() {
  //   setApartmentModalIsOpen(true);
  // }
  //
  // function closeApartmentModal() {
  //   setApartmentModalIsOpen(false);
  // }

  return (
    <>
      <style type="text/css">
        {`

        .col-dou { 
          
         
        }

    

        .header {
           font-family : 'Urbanist';
           font-size : 23px; 
           font-weight : 600;

        }

        .price {
            font-family : 'Urbanist';
            font-size : 20px; 
            font-weight : 600;
            padding : 0;
 
        }

        .about-apartment{
            font-family : 'Urbanist';
            font-size : 24px; 
            font-weight : 500;
            
        }

        .info-apartment{
            font-family : 'Urbanist';
            font-size : 16px; 
            font-weight : 400;
            
        }
        .amenities {
            margin-top :70px;
            font-family : 'Urbanist';
            font-size : 24px; 
            font-weight : 500;
        }


        .image-4 {
            margin-top : 20px;
        }
        .col-1 {
            padding : 5px;
            margin-top : 10px;
        }

        .col-2 {
            padding : 5px;
            margin-top : 10px;
        }

        .col-3 {
            padding : 5px;
            margin-top : 10px;
        }


        .btn-flat {
            background-color: #1A64AC;
            color: white;
            transition: all .2s ease-in-out;
            margin-top : 3em;
            width : 50%;
            align-self : center ;
            font-weight : 600;
          }
      
          .btn-flat:hover {
      
              color: white;
              transform : scale(1.1);
            }
      



        `}
      </style>

      {/* <ApartmentModal visible = {apartmentModalIsOpen} close = {closeApartmentModal} essential = {false}/> */}

      <CustomNavbar />

      <Container>
        <Row>
          <Col lg={6} md={12} sm={12} xs={12} className="col-1">
            <img
              src="assets/img/juno-lite/juno-yaba8.jpg"
              className="image-1"
              width="100%"
              height="100%"
              alt=""
            />
          </Col>

          <Col lg={3} md={6} sm={6} xs={6} className="col-2">
            <img
              src="assets/img/juno-lite/juno-yaba3.jpg"
              className="image-2"
              width="100%"
              height="100%"
              alt=""
            />
          </Col>

          <Col lg={3} md={6} sm={6} xs={6} className="col-3">
            <img
              src="assets/img/juno-lite/juno-yaba1.jpg"
              className="image-3"
              width="100%"
              height="47%"
              alt=""
            />

            <img
              src="assets/img/juno-lite/juno-yaba9.jpg"
              className="image-4"
              width="100%"
              height="47%"
              alt=""
            />
          </Col>
        </Row>
        <Row>
          <Col lg={6} md={12} sm={12} xs={12}>
            <p
              style={{
                marginTop: 20,
              }}
            >
              <span className="header">Juno Lite</span>
              <a
                href="https://goo.gl/maps/Qnh2KNN8cucYnBze8"
                target="_blank"
                style={{
                  padding: 0,
                  fontFamily: "Urbanist",
                  fontWeight: "400",
                  fontSize: "16px",
                  textAlign: "center",
                  color: "#0B3F89",
                  margin: 0,
                  paddingLeft: 20,
                  marginRight: 20,
                }}
              >
                <img
                  width="16px"
                  src="assets/img/Group5/location-icon.png"
                  style={{ marginRight: 5, marginBottom: 8 }}
                />
                Yaba, Lagos
                <img
                  src="assets/img/external-link.svg"
                  style={{ marginLeft: 5, marginBottom: 7 }}
                />
              </a>

              <span className="price">₦375,000/ Yearly</span>
            </p>

            <p className="about-apartment">About this apartment</p>
            <p className="info-apartment">
              Juno's mission is to empower young African professionals
              to thrive in functional communities.
            </p>

            <Button
              variant="flat"
              href="https://zfrmz.com/lgdXNCGV3j2JYC6vkD4X"
              target="_blank"
            >
              {" "}
              Book Now
            </Button>
          </Col>
          <Col lg={6} md={12} sm={12} xs={12}>
            <p className="amenities">Amenities</p>

            <Row>
              <Col>
                <FacilitiesIcon
                  icon="assets/img/kitchen-1.png"
                  title="Kitchen"
                  height={70}
                />
              </Col>
              <Col>
                <FacilitiesIcon
                  icon="assets/img/desk icon-1.png"
                  title="Study / Work Space"
                  height={70}
                />
              </Col>
              <Col>
                <FacilitiesIcon
                  icon="assets/img/console-1.png"
                  title="Lounge"
                  height={70}
                />
              </Col>
              <Col>
                <FacilitiesIcon
                  icon="assets/img/washing machine icon-1.png"
                  title="Laundry"
                  height={70}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <FacilitiesIcon
                  icon="assets/img/ac-1.png"
                  title="Air Conditioning"
                  height={70}
                />
              </Col>

              <Col>
                <FacilitiesIcon
                  icon="assets/img/light-1.png"
                  title="24 / 7 Blended Power Supply"
                  height={70}
                />
              </Col>
              <Col>
                <FacilitiesIcon
                  icon="assets/img/cctv icon-1.png"
                  title="CCTV Security"
                  height={70}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default JunoKarimu;
