import { Icon } from '@iconify/react'
import Button from 'react-bootstrap/Button'
import { Modal as BootstrapModal } from 'react-bootstrap'
import React, { useEffect, useState } from 'react'

export function MyVerticallyCenteredModal(props) {
  return (
    <BootstrapModal
      pill
      {...props}
      size="lg"
      aria-labelledby="contained-modal-tie-vcenter"
      centered
    >
      <BootstrapModal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </BootstrapModal.Footer>
      <BootstrapModal.Body>
        <ZohoDeskWidget /> {/* Embed the Zoho Desk widget */}
      </BootstrapModal.Body>
    </BootstrapModal>
  )
}

const ZohoDeskWidget = () => {
  const [loading, setLoading] = useState(true) // State to track loading status

  useEffect(() => {
    // Initialize Zoho Desk widget
    const script = document.createElement('script')
    script.src =
      'https://desk.zoho.com/portal/api/feedbackwidget/814438000000545029?orgId=837420379&displayType=embeded'
    script.async = true

    script.onload = () => {
      setLoading(false) // Set loading to false when the script has loaded
    }

    document.body.appendChild(script)

    // Cleanup function
    return () => {
      // Remove the widget when the component unmounts
      const widgetContainer = document.getElementById('zsfeedbackwidgetdiv')
      if (widgetContainer) {
        widgetContainer.innerHTML = ''
      }
    }
  }, [])

  return (
    <>
      {loading ? (
        <div
          className="modal"
          style={{
            color: 'black'
          }}
        >
          Please wait...
        </div> // Show loading message while loading
      ) : (
        <div id="zsfeedbackwidgetdiv"></div> // Render Zoho Desk widget when loading is complete
      )}
    </>
  )
}
