import Carousel from "react-bootstrap/Carousel";
import { Button } from "react-bootstrap";

function HeroSection() {
  return (
    <>
      <style type="text/css">
        {`

img.d-block.w-100 {
  height: 100%;
  object-fit: cover;
}

.image-container{
height:480px
}

li.active {
  background-color: #F9CB73;
}

.sliderHeader{
  width:100%;
  text-align: left;
  color: #FFF;
  font-size:30px;

font-style: normal;
font-weight: 700;
line-height: 90%; /* 57.6px */
letter-spacing: 0px;

}

.sliderSubHeader{
  width:100%;
  text-align: left;
  font-size:20px; 
  font-weight: 500;
}
     

      @media only screen and (min-width: 1024px) {
        .rowEdit{
          // gap:70px;
          display:flex;
        }
    
        .image-container{
          height:680px
          }

      

      }

      @media only screen and (min-width: 600px) {
        
        .rowEdit{
          gap: 70px;
        }
             
        .image-container{
          height:580px
          }

              .sliderHeader{
font-size: 50px;

          }
          

      }


      @media only screen and (max-width: 400px) {
       


      }

   
  
      `}
      </style>

      <div>
        <Carousel>
          <Carousel.Item>
            <div className="image-container">
              <img
                src="assets/img/hero/slide-1.jpeg"
                alt="First slide"
                style={{
                  objectFit: "cover",
                  height: "100%",
                }}
              />
            </div>

            <Carousel.Caption>
              <h3 className="sliderHeader">
                {" "}
                Modern Studio Rooms with 24 Hours Power
              </h3>
              <p className="sliderSubHeader">Juno Karimu</p>
              <div
                style={{
                  display: "flex",
                  alignItems: "left",
                }}
              >
                <Button
                  style={{
                    width: "150px",
                    marginTop: 10,
                    marginBottom: 30,
                    borderRadius: "25px",
                    backgroundColor: "#F9CB73",
                    fontWeight: "600",
                  }}
                  variant="warning"
                  target="_blank"
                  size="md"
                  pill
                  href={
                    "https://forms.zohopublic.com/saundersdev/form/JunoMembershipForm/formperma/8r3oS7B-9euyaMPW6LOBUf4JDXWh0b_1Bh17cipMN7Y"
                  }
                >
                  Book Now
                </Button>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <div className="image-container">
              <img
                className="d-block w-100"
                src="assets/img/slide-2.png"
                alt="First slide"
                style={{
                  objectFit: "cover",
                }}
              />
            </div>
            <Carousel.Caption>
              <h3 className="sliderHeader">Home for Young Professionals</h3>
              <p className="sliderSubHeader">The power of community</p>
              <div
                style={{
                  display: "flex",
                  alignItems: "left",
                }}
              >
                <Button
                  style={{
                    width: "150px",
                    marginTop: 10,
                    marginBottom: 30,
                    borderRadius: "25px",
                    backgroundColor: "#F9CB73",
                    fontWeight: "600",
                  }}
                  variant="warning"
                  target="_blank"
                  size="md"
                  pill
                  href={
                    "https://forms.zohopublic.com/saundersdev/form/JunoMembershipForm/formperma/8r3oS7B-9euyaMPW6LOBUf4JDXWh0b_1Bh17cipMN7Y"
                  }
                >
                  Join Community
                </Button>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <div className="image-container">
              <img
                className="d-block w-100"
                src="https://res.cloudinary.com/dan4b75j7/image/upload/v1714660873/Juno/juno-website/kr6tsw3jtcddm7nkwtpn.png"
                alt="First slide"
                style={{
                  objectFit: "cover",
                }}
              />
            </div>
            <Carousel.Caption>
              <h3 className="sliderHeader">
                Flexible Payment at Juno Social Living
              </h3>
              <p className="sliderSubHeader">Your Cozy Living Space</p>
              <div
                style={{
                  display: "flex",
                  alignItems: "left",
                }}
              >
                <Button
                  style={{
                    width: "150px",
                    marginTop: 10,
                    marginBottom: 30,
                    borderRadius: "25px",
                    backgroundColor: "#F9CB73",
                    fontWeight: "600",
                  }}
                  variant="warning"
                  target="_blank"
                  size="md"
                  pill
                  href={
                    "https://forms.zohopublic.com/saundersdev/form/JunoMembershipForm/formperma/8r3oS7B-9euyaMPW6LOBUf4JDXWh0b_1Bh17cipMN7Y"
                  }
                >
                  Book Now
                </Button>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
      </div>
    </>
  );
}

export default HeroSection;
