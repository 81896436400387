import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Steps from "./Steps";

const GetAJunoSection = () => {
  return (
    <div
      id="how-it-works"
      style={{
        backgroundColor: "white",
      }}
    >
      <Container>
        <p
          style={{
            textAlign: "center",
            fontFamily: "Urbanist",
            paddingTop: 64,
            paddingBottom: 64,
            lineHeight: "normal",
            fontWeight: 700,
          }}
          className="mm-text"
        >
          Become a Juno Living Member in 3 easy steps
        </p>
        <Row>
          <Col>
            {" "}
            <Steps
              img="https://res.cloudinary.com/dan4b75j7/image/upload/v1715631715/Juno/juno-website/jk96mclgf0m0t2h9rdcy.png"
              title="Book"
              text="Reserve your spot by clicking"
              url="https://forms.zohopublic.com/saundersdev/form/JunoMembershipForm/formperma/8r3oS7B-9euyaMPW6LOBUf4JDXWh0b_1Bh17cipMN7Y"
              urlText="Book Now"
            />
          </Col>
          <Col>
            {" "}
            <Steps
              img="https://res.cloudinary.com/dan4b75j7/image/upload/v1715631712/Juno/juno-website/vqkstwoqiyuile7bwnzb.png"
              title="Pay"
              text="Confirm your booking by making payment"
            />
          </Col>
          <Col>
            {" "}
            <Steps
              img="https://res.cloudinary.com/dan4b75j7/image/upload/v1715631712/Juno/juno-website/crtpkfz7osw7kx4sqntx.png"
              title="Move in"
              text="You are all set as a resident"
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default GetAJunoSection;
